import {mapGetters, mapMutations} from "vuex";
import categoryListCard from "@/components/category-list-card/index.vue";
//sections

export default {
    name: "product-carousel",
    components: {
        categoryListCard
    },
    data() {
        return {}
    },
    created() {
    },
    props: {
        item: {
            list: {
                type: Object,
                default: () => {
                }
            }
        },
        list: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        ...mapGetters({
            currentProduct: 'catalog/currentProduct'
        })
    },

    methods: {
        // carouselNav($event, direction) {
        //   Array.from(this.$refs['carousel']).forEach(el => {
        //     el.advancePage(direction);
        //   })
        // },
        ...mapMutations({})
    }
}
